.footer {
  /* background-color: aquamarine; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 120px;
  border-top: 0.5px solid #bbbbbb;
  padding-top: 30px;
  padding-bottom: 20px;
  position: relative;
}
ul li {
  list-style: none;
  padding-left: 18.67px;
}

.footer__text h4 {
  font-size: 16px;
  font-weight: 700;
  color: #666666;
  line-height: 28px;
}

.footer__icons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 0;
}

@media only screen and (max-width: 920px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: 0 20px;
    padding-top: 10px;
    padding-bottom: 15px;
    position: relative;
  }
  .footer__text h4 {
    font-size: 14px;
  }
  .footer__icons {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 0px;
  }
}
