.navwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 20px 30px rgba(187, 187, 187, 0.24);
  height: 77px;
}
.navsignup1,
.navsignup1:hover,
.navsignup1:active,
.navsignup1:focus {
  background: #ffffff;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  color: #039c71;
  border: 1px solid #039c71;
  width: 155px;
  text-align: center;
  height: 47px;
  margin-right: 2rem;
  border-radius: 8px;
}
.prrf {
  width: 280px;
}
.navsignup,
.navsignup:active,
.navsignup:focus,
.navsignup1:focus {
  color: #ffffff;
  background: linear-gradient(
    78.18deg,
    #5fb49c 0%,
    rgba(15, 188, 73, 0.88) 57.23%,
    #2e6af0 119.44%
  );
  border-radius: 8px;
  border: none;
  border-radius: 8px;
}
.otherwrap {
  width: 33%;
}
@media (min-width: 450px) {
  .otherwrap {
    min-width: 380px;
  }
}
.logoarea {
  width: 65%;
  padding-left: 4rem;
}
.navwrap {
  padding: 1rem;
  padding-left: 4rem;
}
.rigistxt {
  text-align: center;
  color: #666666;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
  font-size: 14px;
}

.plicy {
  font-size: 14px;
  line-height: 19px;
  color: #039c71;
  font-weight: 500;
}

.tmid {
  justify-content: center;
}
.pushtopundernav {
  padding-top: 150px;
  padding-bottom: 100px;
}
.fixfdnav {
  position: fixed;
  width: 100%;
  background: #ffff;
  z-index: 1000;
}

.pushtopundernav {
  background: #f9f9f9;
}
.ctrl1 {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #333333;
  text-align: center;
  padding-bottom: 3rem;
  padding-top: 1rem;
}
.whitcont1 {
  padding: 2rem 2.5rem;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 0.5rem;
  border-top: 3px solid #039c71;
}
.fmc2 {
  height: 55px !important;
  padding: 0rem 1rem;
  border-radius: 8px;
  background: #ffffff !important;
  padding-right: 1.5rem;
  font-size: 14px;
}
.bg-white23 {
  background: white;
}
.wrapa1 {
  text-align: right;
  padding-right: 1rem;
}
.esyes {
  cursor: pointer;
  position: relative;
  bottom: 2.5rem;
}
.charvalid {
  font-size: 8px;
}
.signnp {
  background: linear-gradient(
    78.18deg,
    #5fb49c 0%,
    rgba(15, 188, 73, 0.88) 57.23%,
    #2e6af0 119.44%
  );
  border-radius: 8px;
  border: none;
  width: 100% !important;
  padding: 1rem;
  font-weight: bold;
}
.mobileham {
  display: flex;
  flex-direction: column;
}
@media (max-width: 780px) {
  .otherwrap {
    display: none;
  }
  .mobileham {
    display: flex;
  }
  .ham1,
  .ham2 {
    width: 29px;
    display: inline;
    height: 2px;
    background: #333333;
    margin-top: 0.4rem;
  }
  .ham3 {
    width: 15px;
    display: inline;
    height: 2px;
    background: #333333;
    margin-top: 0.4rem;
  }
  .whitcont1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .logoarea {
    padding-left: 0px;
  }
  .navwrap {
    padding-left: 3rem;
  }
  .ismobile {
    display: flex;
    background: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;
    box-shadow: 0px 10px 16px rgba(187, 187, 187, 0.24);
  }
  .siggnup,
  .navsignup1 {
    margin-top: 1rem;
    /* padding-bottom: 1rem; */
  }
  .navsignup1 {
    margin-right: 0px;
  }
  .nvtimes {
    font-size: 3rem !important;
    margin-right: 1rem;
  }
  .navwrap {
    box-shadow: none;
  }
  .nvtimes {
    display: block;
  }
}
@media (min-width: 780px) {
  .ismobile {
    display: none;
  }
  .nvtimes {
    display: none;
  }
}

.cicck {
  font-size: 14px;
  text-align: center;
  color: #999999;
  padding-bottom: 1rem;
}
.plicy1q {
  cursor: pointer;
}
@media (min-width: 1200px) {
  .container {
    max-width: 86% !important;
  }
}
