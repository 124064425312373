.mt-2 {
  margin-bottom: 55px;
  border-bottom: 0.5px solid #bbbbbb;
  font-size: 12px;
  font-weight: 325;
  color: #666666;
  line-height: 14.4px;
  padding-bottom: 10px;
}
.form__main {
  padding-top: 5px !important;
}
.container {
  padding: 0 15px;
}
.w-100 {
  height: 50px !important;
  background-color: var(--accent-color) !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: var(--white-color);
  width: auto;
  box-shadow: 0 0 0 0 !important;
}
.w-150 {
  height: 50px !important;
  background-color: var(--white-color) !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: var(--accent-color);
  width: 100%;
  border: 2px solid var(--accent-color) !important;
  box-shadow: 0 0 0 0 !important;
  /* line-height: 19.47; */
}
.form-group {
  position: relative;
  margin-top: -15px;
}

.form-group .form-area {
  outline: none;
  border: 1px solid #bbbbbb;
  padding: 16px 13px;
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  height: 55px;
  transition: all 0.12s;
  background: var(--primary-background-color);
}

.form-group .form-area2 {
  /* outline: none; */
  border: 1px solid #bbbbbb;
  font-size: 16px;
  border-radius: 5px;
  /* width: 100%; */
  height: 55px;
  transition: all 0.12s;
  background: var(--primary-background-color);
}

.form-group .form-area:valid + .form-label {
  top: -8px;
  padding: 0 3px;
  font-size: 14px;
  color: #8d8d8d;
}

.form-group .form-area:focus {
  border: 2px solid #ccdcff;
}

.form-group .form-label {
  color: #575656;
  position: absolute;
  top: -18px;
  left: 30px;
  background: var(--primary-background-color);
  padding: 0 18px !important;
  font-size: 14px;
  transition: all 0.12s;
}

@media screen and (max-width: 770px) {
  .w-100,
  .w-150 {
    height: 40px !important;
    font-size: 12px !important;
  }
}
