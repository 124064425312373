/* .fp-auth-page-landing {
  position: relative;
  transition: all 0.3s ease;
  background: linear-gradient(to left, #1564f3 0, #0143ba 100%);
  height: 100%;
}

.fp-auth-pattern-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../Resource/fp-auth-transparent-banner.png");
  z-index: 1;
}

.fp-auth-page-landing .fp-auth-page-form {
  background: #ffffff;
  margin: 90px;
  padding: 40px 60px;
  border-radius: 3px;
   padding: 35px; 
  position: relative;
} */

.fp-register-name {
  display: flex;
  /* justify-content: space-between; */
  /* min-width: 200px; */
}

@media only screen and (max-width: 991px) {
  .fp-register-name {
    flex-direction: column;
  }
}
