.redesignheroSection {
  background-color: var(--primary-background-color);
  padding: 231px 0px;
  display: flex;
  position: relative;
  align-items: center;
}
.rdheroSection__headline {
  width: 55%;
}
.redesignheroSection__headline--main {
  font-size: 56px;
  font-weight: 700;
  line-height: 70px;
  z-index: 100;
  margin-bottom: 25px;
}
.homemainImage > img {
  width: 80%;
  height: 80%;
  object-fit: cover;
  display: block;
}
.rdheroSection__headline--main {
  font-size: 56px;
  font-weight: 700;
  line-height: 70px;
  z-index: 100;
}
.rdheroSection__headline > p {
  z-index: 100;
  color: #666666;
  width: 500px;
}

@media only screen and (max-width: 800px) {
  .redesignheroSection {
    padding: 231px 0px 179px 0px;
  }
  .hero__vector {
    position: absolute;
    left: 0;
    top: 102px;
    display: block;
    width: 349px;
    height: 570px;
  }
  .redesignheroSection__headline--main {
    font-size: 28px;
    line-height: 34.21px;
    opacity: 1;
    transform: translate(0px, 0px);
  }
  .rdheroSection__headline {
    width: 100%;
    z-index: 10;
  }
  .od122{
    text-align: center;
  }
  .rdheroSection__headline > p {
    width: 307px;
    font-size: 14px;
    transform: translate(0px, 0px);
    line-height: 20px;
  }
  .btn-gtstrted {
    transform: translate(0px, 0px);
  }
  .redirlnltvd {
    display: block !important;
  }

  .rdheroSection__images {
    width: 50%;
  }

  .homeImage > img,
  .homeImage > iframe {
    width: 75%;
    height: 75%;
  }
}
iframe {
  border: none;
}
.btn-gtstrted {
  background: linear-gradient(
    78.18deg,
    #5fb49c 0%,
    rgba(15, 188, 73, 0.88) 57.23%,
    #2e6af0 119.44%
  );
  color: #ffffff;
  margin-top: 39px;
  padding: 20px 51px;
  display: inline-block;
  border-radius: 5px;
}
.btn-gtstrted:hover {
  color: #ffffff !important;
}
.redirlnltvd {
  display: none;
  margin-top: 13px;
  color: #039c71;
  font-weight: 700;
  font-size: 12px !important;
  transform: translate(0px, 0px);
}
/* partnersection styling */
.partner-carousel::-webkit-scrollbar {
  width: 0;
  background-color: var(--secondary-background-color);
}
.partner-carousel {
  height: 120px;
  /* width: 100vw; */
  padding: 0 10%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.prtncardsply {
  display: none;
}
@media screen and (max-width: 800px) {
  .partner-carousel {
    overflow: scroll;
  }
  .prtncardsply {
    display: block;
    display: block !important;
  }
  .logo2{
    width: 120px;
  }
  p{
    font-size: 14px;
  }
  .partner{
    background:  var(--primary-background-color);
  }
  .rdstepSession1__heading>h1 {
    font-size: 25px;
  }
  .redesignheroSection__headline--main{
    font-size: 25px;
  }
  .rdbtn-info {
    font-size: 14px;
  }
}
@media screen and (max-width: 1023px) {
  .partner-carousel {
    overflow: scroll;
  }
  
}

.partner-logo,.partner-logo2,.partner-logo1,.partner-logo3 {
  height: 40px;
  /* margin-right: 60px; */
  object-fit: contain;
}
.widtdd{
  width: 140px;
  margin-right: 60px;
  text-align: center;
}
.rdstepSession1 {
  padding: 74px 0px;
  background-color: var(--primary-background-color);
}
.rdstep1 {
  align-items: flex-start;
}
.rdstep2 {
  align-items: center;
}
.rdstep3 {
  align-items: flex-end;
}
.rdstepSession1__heading > h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 28px;
}

.rdstepSession1__heading > p {
  font-size: 20px;
  font-weight: 325;
  line-height: 28px;
  color: #666666;
  font-weight: 400;
  margin-bottom: 49px;
}

.rdstepSession1__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rdstepSession1__steps {
  display: flex;
}

.rdstepSession1__steps--item {
  text-align: center;
  margin: 60px 0;
  width: 23vw;
  border-top: 0.5px solid var(--accent-color);
  position: relative;
  display: flex;
  flex-direction: column;

  /* background-color: aqua; */
}
.rdstepcnt {
  /* text-align: center;
  padding: 10px 20px;
  font-size: 24px;
  font-weight: 700;
  color: var(--white-color);
  border-radius: 50%;
  background-color: #039c71;
  position: absolute;
  top: -28px; */
  text-align: center;
  padding: 10px 20px;
  font-size: 24px;
  font-weight: 700;
  color: var(--white-color);
  border-radius: 50%;
  background-color: #039c71;
  position: relative;
  bottom: 7rem;
}
.blkcd {
  display: inline-block;
  display: none;
  background-color: var(--primary-background-color);
  position: absolute;
  top: -2px;
  left: 0;

  height: 22px;
  width: 90%;
  border-top: 2px dashed var(--accent-color);
}
.socc11 {
  width: 50%;
  border: none;
  top: -4px !important;
}
.rdstepcnt23d {
  position: relative;
  bottom: 5.4rem;
  z-index: 3;
}
.onmiddle {
  padding: 108px 11px 40px 11px !important;
}
.blkcdright {
  display: inline-block;
  display: none;
  background-color: var(--primary-background-color);
  position: absolute;
  top: -11px;
  right: 0;
  height: 22px;
  width: 158px;
}
.rdstepscontentwrapper {
  display: flex;
  justify-content: space-between;
}
.rdstepscontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32vw;
}

.rdstepscontent > h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-top: 65px;
}
.rdstepSession1__steps--item > div {
  background-color: #039c71;
  height: 4px;
  display: none;
  width: 45px;
  margin: 30px auto;
}
.rdstepSession1__steps--item > p {
  display: none;
}
.rdstepscontent > div {
  background-color: #039c71;
  height: 4px;
  width: 45px;
  margin: 30px auto;
  margin-top: 20px;
}
.rdstepscontent > h4 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  width: 300px;
  color: #666666;
  text-align: center;
}
.rdstepscontent > p {
  font-weight: 325;
  color: #666666;
  width: 63%;
  margin-bottom: 73px;
  font-size: 17px;
  text-align: center;
}
.mobphder,
.mobscnhder {
  display: none;
}
@media only screen and (max-width: 800px) {
  .rdstepSession1__heading > p {
    font-size: 13px;
  }
  .rdstepSession1__heading > h {
    font-size: 16px;
  }
  .rdstepscontentwrapper {
    display: none;
  }
  .blkcdright {
    display: block;
  }
  .blkcd {
    display: block;
  }
  .rdstepSession1__steps--item > div {
    display: block;
  }
  .rdstepSession1__steps--item > p {
    display: block;
  }
  .rdstepSession1__steps {
    flex-direction: column;
    margin-top: 40px;
  }
  .mobphder,
  .mobscnhder {
    font-size: 16px;
    display: block;
  }
  .mobscnhder {
    font-size: 14px;
    color: #666666;
    font-weight: 700;
    line-height: 24px;
  }

  .rdstepcnt {
    /* left: 115px; */
  }
  .flefd {
    display: flex;
  }
  .rdstepSession1__steps--item {
    margin: 0 0;
    width: 88vw;
    border-top: 2px dashed var(--accent-color);
    padding: 66px 11px 40px 11px;
    display: flex;
    align-items: center;
  }
  .rdstep1 {
    border-right: 2px dashed var(--accent-color);
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
  }
  .rdstep2 {
    border-left: 2px dashed var(--accent-color);
    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
  }
  .rdvideoSection > iframe {
    width: 90% !important;
    height: 254px !important;
  }
  .rdvideoSection__heading {
    font-size: 16px !important;
    line-height: 26px !important;
    top: 2vh !important;
  }
  .rdfooterwrapper {
    display: none !important;
  }
  .mobilefooter {
    border-top: 0.5px solid #888888ab;
    display: block !important;
    flex-direction: column;
    justify-content: center;
    padding-top: 24px;
    padding-bottom: 15px;
  }
  .mobfootericons {
    display: flex;
    justify-content: center;
  }
  .ftrtxt {
    text-align: center;
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    color: #666666;
  }
}
.rdbtn-info {
  display: inline-block;
  background: linear-gradient(
    78.18deg,
    #5fb49c 0%,
    rgba(15, 188, 73, 0.88) 57.23%,
    #2e6af0 119.44%
  );
  color: #ffffff;
  font-size: 18px;
  padding: 18px 53px;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid;
  transition: all 0.4s;
  margin-bottom: 1rem;
  /* border: none; */
}
.rdbtn-info:hover {
  color: #039c71;
  border: 1px solid #039c71;
  background: var(--primary-background-color);
}
/* Video section styling */
.rdvideoSection {
  width: 100%;
  text-align: center;
  position: relative;
  background: var(--primary-background-color);
  padding-bottom: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rdvideoSection__heading {
  position: absolute;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  color: var(--white-color);
  z-index: 10;
  top: 10vh;
}
.rdvideoSection > iframe {
  width: 72%;
  height: 401px;
  border-radius: 6px;
  margin: auto 0;
  opacity: 0.8;
}
/*Footer section*/
.rdfooterSection {
  background-color: var(--primary-background-color);
  padding: 65px 0px;
}
.rdfooterwrapper {
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
}
.firstdiv {
  width: 19%;
}
.fourthdiv {
  width: 31%;
}
.rdfootercontent > h3 {
  font-size: 18px;
  font-weight: 700;
  color: #1d293f;
  line-height: 32px;
  margin-bottom: 23px;
}
.rdfootercontent > p {
  font-size: 16px;
  font-weight: 400;
  color: #7c8087;
  line-height: 35px;
}
.scddvlistitems > li {
  list-style: none;
  padding: 0;
  color: #7c8087;
  font-weight: 400;
  margin-bottom: 20px;
}
.scddvlistitems > li:hover {
  color: #2e6af0;
}
.fthdvpara {
  margin-bottom: 0;
}
.ftrsub-btn {
  background: linear-gradient(
    78.18deg,
    #5fb49c 0%,
    rgba(15, 188, 73, 0.88) 57.23%,
    #2e6af0 119.44%
  );
  color: #fff;
  text-align: center;
  padding: 17px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.4s;
}
.ftrsubscribeform {
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 8px 19px 8px 19px;
  margin-top: 1rem;
}
.ftsubinpt {
  padding: 4px 4px 4px 43px;
  border: none !important;
  outline: none;
  margin-top: 8px;
  margin-right: 12px;
  background-image: url(../../assets/email.png);
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 10px;
}
.ftsubinpt::placeholder {
  color: #8c97ac;
  font-size: 16px;
  font-weight: 400;
}
.ftsubinpt:focus {
  outline: none !important;
}
.ftrwmrgin {
  margin-bottom: 0;
}
.mobilefooter {
  display: none;
}
.ffcet {
  justify-content: center;
}
@media (min-width: 800px) {
  .rdstepSession1__heading {
    padding-bottom: 2rem;
  }
  .rdstepcnt,
  .rdstepSession1__steps--item {
    display: none;
  }
}

@media (max-width: 500px) {
  .rdstepSession1__steps--item > div {
    height: 5px;
  }
  .affrd {
    height: 0px !important;
    margin: 0px !important;
  }
  .rdstepcnt1 {
  }
  .rdstepcnt2 {
    top: -6rem;
  }
}

.xstepcnt {
  text-align: center;
  padding: 10px 20px;
  font-size: 24px;
  font-weight: 700;
  color: var(--white-color);
  border-radius: 50%;
  background-color: #039c71;
  position: relative;
  /* bottom: 7rem; */
}
.flefd {
  display: flex;
  justify-content: space-between;
  width: 84%;
  align-items: center;
  margin: auto;
  position: relative;
  top: 0.5rem;
  z-index: 10;
  margin-bottom: 4rem;
}


.ffldd {
  border: 2px solid #039c71;
  width: 84%;
  align-items: center;
  margin: auto;
  position: relative;
  top: 2.3rem;
}
@media (max-width: 800px) {
  .flefd,
  .ffldd {
    display: none;
  }
  .blaacd {
    width: 11%;
    border: none;
    top: -3px !important;
    background: var(--primary-background-color);
    height: 5px;
    position: absolute;
    right: 0rem;
  }
  .rdstep1 {
    padding: 5rem 2rem;
  }
  .onmiddle {
    padding: 36px 11px 95px 34px !important;
    border-bottom-left-radius:3rem;
  }
  .rdstepcnt23d {
    position: relative;
    bottom: 6.34rem;
    z-index: 3;
  }
  .dsd1 {
    bottom: 3.5rem;
    left: -.1rem;
  }
  .hiedd {
    height: 0px !important;
  }
  .rdstep3 {
    padding-top: 4rem;
  }
  .newwidth {
    width: 50%;
  }
  .newwidth1{
    background-color: var(--primary-background-color);
    position: absolute;
    top: -3px;
    left: 0;
    height: 4px;
    width: 36px !important;
    /* background: red; */
  }
}

@media(min-width:1500px){
  .flefd {
    width: 85%;
    max-width: 100% !important;
  }
  .ffldd {
  width: 84%;
  }
  .rg11{
    /* margin-left: 8rem; */
  }
  .rgzc1{
    max-width: 85% !important;
  }
}
.logoarea{ 
  padding-left: 1rem;
}