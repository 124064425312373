.input-group {
  position: relative;
  margin-bottom: 25px;
}

.input-group .input-area {
  outline: none;
  border: 1px solid #dadce0;
  padding: 16px 13px;
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  transition: all 0.12s;
}

.input-group .input-area:valid + .input-label {
  top: -8px;
  padding: 0 3px;
  font-size: 14px;
  color: #8d8d8d;
}

.input-group .input-area:focus {
  border: 2px solid #ccdcff;
}

.input-group .input-label {
  color: #575656;
  position: absolute;
  top: -10px;
  left: 30px;
  background: #ffffff;
  padding: 0 18px;
  font-size: 14px;
  transition: all 0.12s;
}
.eye {
  position: absolute;
  right: 0;
  top: 5px;
}
#hide1 {
  display: none;
  position: absolute;
  right: 20px;
  top: 10px;
}
#hide2 {
  position: absolute;
  right: 20px;
  top: 10px;
}
