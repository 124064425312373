/* Animation */
@keyframes changeOpacity {
  0% {
    opacity: 0.25;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

#fp-login-auth-page {
  background-color: var(--primary-background-color);
  /* overflow: hidden; */
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.fp-login-auth-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.fp-login-auth-page-landing {
  width: 55%;
  height: 65vh;
  background: var(--white-color);
  border-radius: 8px;
  border-top: 4px solid var(--accent-color);
  margin-top: 50px;
}
.fp-login-auth-page-landing-subtitle {
  padding-top: 40px;
}
@media only screen and (max-width: 600px) {
  .fp-login-auth-page-landing {
    width: 85%;
    margin-top: 5px;
    height: 75vh;
  }
}
.row {
  margin-bottom: 20px;
}

.fp-login-auth-page-landing-form {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  text-align: center;
}

.fp-login-notification {
  background: #e0f4fb;
  border-color: #bce7f6;
  color: var(--accent-color);
  margin-bottom: 20px;
}
.fp-login-auth-page-subheading {
  text-align: center;
  padding: 0 10px;
}
.fp-forgotPassword {
  text-align: right;
  font-size: 14px;
  line-height: 19.5px;
  font-weight: 700;
}
.fp-create-account-wrapper > a:-webkit-any-link {
  color: var(--accent-color);
  font-size: 14px;
  line-height: 19.5px;
  font-weight: 700;
}

.fp-forgotPassword-link:-webkit-any-link {
  color: var(--accent-color) !important;
}

.fp-login-notification .fp-login-notification-content-wrapper {
  display: flex;
  flex: 1 1 100%;
  padding: 16px 0 0 16px;
  flex-flow: row wrap;
  min-width: 0;
}

.fp-login-notification .fp-login-notification-content-title {
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 16px;
  margin-top: 8px;
}

.fp-login-notification .fp-login-notification-content-text {
  color: inherit;
  margin: 16px 0;
  font-size: 16px;
  line-height: 1.4;
}

.fp-login-notification .fp-login-notification-content {
  text-align: center;
  flex: 1 1 250px;
  margin: 0 16px 16px 0;
  min-width: 0;
  word-wrap: break-word;
}

.fp-login-auth-page-landing-subtitle {
  font-size: 24px;
  font-weight: 700;
  color: #333333 !important;
  line-height: 29.21px;
  text-align: center;
  margin-bottom: 30px;
}

.error-list.fp-errors-display-list {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
}

.error-list.fp-errors-display-list .error-item {
  font-size: 17px;
  margin: 10px 0;
  color: #f00;
  animation: changeOpacity 3.5s infinite;
  display: block;
}

/* .fp-login-auth-page-sidebar-text .fp-login-auth-page-sidebar-action-text {
  margin: 30px 0 0;
  padding: 0px 40px;
}

.fp-login-auth-page-sidebar-text .fp-login-auth-page-sidebar-action-text h2 {
  color: #ffffff;
  font-size: 37px;
  line-height: 42px;
  max-width: 250px;
  font-weight: 500;
}
.fp-login-auth-page-sidebar-text .fp-login-auth-page-sidebar-action-text p {
  color: #ffffff;
  font-size: 17px;
  font-weight: 400;
  opacity: 0.48;
  line-height: 30px;
  margin: 20px 0px;
  max-width: 340px;
  padding-bottom: 30px;
}

.fp-login-auth-page-sidebar-text .fp-login-auth-page-sidebar-bg-pattern {
  background: url("../Resource/bg-pattern-sidebar.png");
  background-repeat: no-repeat;
  position: absolute;
  width: 85%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
  top: -10px;
} */
