.form-group1 {
  position: relative;
  margin-bottom: 20px;
}

.form-group1 .form-area {
  outline: none;
  border: 1px solid #dadce0;
  padding: 16px 13px;
  font-size: 16px;
  border-radius: 5px !important;
  width: 100%;
  height: 45px;
  transition: all 0.12s;
}

.form-group1 .form-area:valid + .form-label {
  top: -8px;
  padding: 0 3px;
  font-size: 14px;
  color: #8d8d8d;
}

.form-group1 .form-area:focus {
  border: 2px solid #ccdcff;
}

.form-group1 .form-label {
  color: #575656;
  position: absolute;
  top: -10px;
  left: 30px;
  background: var(--white-color);
  padding: 0 18px !important;
  font-size: 14px;
  transition: all 0.12s;
}

.auth-color {
  background: var(--white-color) !important;
}
