.navbar {
  background-color: #ffffff !important;
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  padding-top: 5px;
  padding-bottom: 15px;
}
.navbar-brand {
  padding-left: 95px;
}
.navbar-right {
  padding-right: 120px;
  margin-top: 20px !important;
}
.name {
  margin-top: 2px;
}
.navbar-logo1 {
  height: 30px;
  filter: invert(100%);
  margin-top: 15px;
}
.navbar-item {
  padding-right: 2rem;
}
.navbar-link {
  color: black;
  font-weight: 600;
}

.btn {
  width: 155px !important;
}
.btn-outline-success {
  color: var(--accent-color) !important;
  background-color: #ffffff !important;
  border-color: var(--accent-color) !important;
}
.btn-info {
  color: #ffffff !important;
  background-color: var(--accent-color) !important;
  border-color: #ffffff !important;
}
@media only screen and (max-width: 991px) {
  .navbar {
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .navbar-brand {
    padding-left: 5px;
  }
  .navbar-nav li {
    padding-left: 20px;
    /* text-align: center; */
  }
  .nav-item .btn {
    border: none;
    color: black;
    text-align: left;
  }
  .nav-item .btn-info {
    color: black !important;
    background-color: #ffffff !important;
    font-weight: 500;
  }
  .nav-item .btn-outline-success {
    color: black !important;
    background-color: #ffffff !important;
    font-weight: 500;
  }
}
